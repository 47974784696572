import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {fetchPosts} from "../../api/redux/post";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import styled from "styled-components";
import Banner from "../../components/home/Banner";
import About from "../../components/About";
import Counter from "../../components/home/Counter";
import {gsap} from "gsap";
import {ScrollToPlugin} from "gsap/ScrollToPlugin";
import Projects from "../../components/home/Projects";
import Testimonial from "../../components/home/Testimonial";
import BusinessDomain from "../../components/home/BusinessDomain";
import GetInTouch from "../../components/GetInTouch";
import {fetchHome} from "../../api/redux/Home";
import Media from "../../components/home/Media";
import Customers from "../../components/home/Customers";
import {fetchMedia} from "../../api/redux/media";
import {Loading} from "../../components/Loading";
import {Helmet, HelmetProvider} from "react-helmet-async";
import InnerBannerV2 from "../../components/InnerBannerV2";
import BrandKit from "../../components/media/BrandKit";
import MediaList from "../../components/media/MediaList";

const Home = () => {

    gsap.registerPlugin(ScrollToPlugin);

    useEffect(() => {
        let goDownClick = document.querySelector('.go-down');
        // let goDownClick = document.querySelector('.go-down');
        if (goDownClick) {
            goDownClick.addEventListener('click', function () {
                gsap.to(window, {
                    scrollTo: '#scrollHere'
                })
            });
        }
    }, [])


    const dispath = useDispatch()

    // api call
    useEffect(() => {
        let api_url = apiEndPoints.HOME
        dispath(fetchHome([api_url]))
    }, [])



    let getPost = useSelector(state => state.home)

    useEffect(() => {
        window.scroll(0, 0)
    }, [getPost])
    //Refactor Data

    const banner = getPost?.posts?.page_data?.banner;
    const about = getPost?.posts?.page_data?.about_section;
    const counter = getPost?.posts?.page_data?.state;
    const domain = getPost?.posts?.page_data?.domain;
    const testimonial = getPost?.posts?.testimonial?.[0]?.detail_info;
    const customer = getPost?.posts?.customers?.[0]?.detail_info;
    const footerTop = getPost?.posts?.page_data?.footer_top;
    const media = getPost?.posts?.media;
    const projects = getPost?.posts?.projects;
    // console.log(getPost?.posts?.page_data?.meta);
    const meta = getPost?.posts?.page_data?.meta;

    return (
        <HelmetProvider>
            {/*dynamic title */}
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{`NDE Infratech`}</title>
                <meta name="description" content={meta && meta.meta_desc ? meta.meta_desc
                    : 'NDE Infratech specializes in designing & implementing hardware, software and other cutting-edge technological ' +
                    'solutions in the public infrastructure domain. We offer products and efficient customized solutions ' +
                    'through our wide network of partners, spread across the globe. With a vast experience in the public sector,' +
                    ' we ensure seamless implementation and execution of a diverse range of projects.'}/>
            </Helmet>
            <StyledSection className={'home'}>
                <Banner data={banner}/>
                <About id={'scrollHere'} data={about}/>
                <Counter data={counter}/>
                <BusinessDomain data={domain}/>
                {/*<Projects data={projects}/>*/}
                <Testimonial data={testimonial} home/>
                <Customers data={customer}/>
                <Media data={media}/>
                <GetInTouch data={footerTop}/>
            </StyledSection>

        </HelmetProvider>


    );
};

const StyledSection = styled.section`
  .about-section__bottom {
    //margin-top: -50px;
      //padding-left: ${props => props.offset ? props.offset + 30 + 'px' : '90px'};
    position: relative;


    //@media (max-width: 767px) {
    //  padding-left: 30px !important;
    //  padding-right: 30px !important;
    //}

    &__single {
      max-width: 100%;
      @media (max-width: 767px) {
        //min-height: 370px;
      }

      &__inner {
        position: relative;
        padding-top: calc(460 / 370 * 100%);
        overflow: hidden;
        //border-right: 1px solid #FFF;

        &:after {
          content: '';
          background-color: #E9E9E9;
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          height: 0%;
          width: 100%;
          transition: .4s cubic-bezier(0.4, 0, 0, 1);
          //mix-blend-mode: multiply;
        }

        a {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0px;
          z-index: 5;
        }

        &__img {
          &:after {
            content: '';
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #ECECEC;
            mix-blend-mode: multiply;
            opacity: 1;
            transition: .8s cubic-bezier(0.4, 0, 0, 1);
            transition-delay: .3s;
          }
        }

        .hover-top {
          position: absolute;
          left: 30px;
          right: 30px;
          top: 40px;
          transition: all .5s cubic-bezier(0.4, 0, 0, 1);
          opacity: 0;
          transform: translateY(-100%);
          z-index: 2;

          h6 {
            font-size: 20px;
            font-weight: 500;
            line-height: 28px;
            color: #404040;
            margin-bottom: 20px;
          }

          ul {
            li {
              position: relative;
              padding-left: 20px;

              &:after {
                position: absolute;
                content: '';
                height: 10px;
                width: 10px;
                background-color: #C1C1C1;
                left: 0;
                top: 0;
                bottom: 0;
                margin: auto;
              }
            }
          }
        }

        .img-top {
          position: absolute;
          top: 40px;
          left: 30px;
          z-index: 2;
          opacity: 1;
          transition: .3s cubic-bezier(0.4, 0, 0, 1);
          height: 60px;
        }

        .circle {
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto;
          height: 50px;
          width: 50px;
          border: 1px solid #fff;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 2;
          opacity: 1;
          transition: .3s cubic-bezier(0.4, 0, 0, 1);

          svg {
            color: #ffffff;
            font-size: 25px;
            transition: .3s cubic-bezier(0.4, 0, 0, 1);
          }
        }

        h4 {
          position: absolute;
          bottom: 30px;
          left: 30px;
          right: 30px;
          font-size: 28px;
          line-height: 36px;
          font-weight: 500;
          color: #FFF;
          z-index: 2;
          transition: all .5s cubic-bezier(0.4, 0, 0, 1);
        }

        .icon {
          position: absolute;
          top: 30px;
          right: 30px;
          z-index: 1;
          transition: all .8s cubic-bezier(0.4, 0, 0, 1);
          transform: rotate(0deg);

          svg {
            li {
              transition: all .8s cubic-bezier(0.4, 0, 0, 1);

            }
          }

        }

        span {
          position: absolute;
          z-index: 1;
          top: 30px;
          left: 30px;
          font-size: 80px;
          line-height: 48px;
          color: #F9F9F9;
          transition: all .5s cubic-bezier(0.4, 0, 0, 1);

        }

      }

      &:hover {
        .about-section__bottom__single__inner {
          &:after {
            height: 100%;
          }

          .icon {
            transform: rotate(-45deg);

            svg {
              line {
                stroke: #061524;
                fill: #061524;
              }
            }
          }

          span {
            top: -10px;
            opacity: 0;
          }


          .hover-top {
            transform: none;
            opacity: 1;
            color: #4F616B;
          }


          &__img:after {
            opacity: 1;
            transition-delay: 0s;
          }

          .img-top {
            transform: translateY(-20px);
            opacity: 0;
          }

          .circle {
            transform: scale(1.9);
            opacity: 0;

            svg {
              transform: rotate(50deg);
            }
          }

          h4 {
            color: #061524;
          }

        }

      }
    }
`;

export default Home;
